<template>
  <div>
    <hotel-add></hotel-add>
  </div>
</template>

<script>
import HotelAdd from './HotelAdd.vue';

export default {
  components: {
    HotelAdd,
  },

  data() {
    return {};
  },
};
</script>
